import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';

import { authenticate, isAuthenticated, signin } from "../../auth";
const Signin = () => {

    const [values, setValues] = useState({
        name: "",
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });

    const { email, password, error, loading, redirectToReferrer } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true })
        signin({ email, password }).then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, loading: false })
            }
            else {
                authenticate(data, () => {
                    window.location.reload();
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    })
                })
            }
        })
    }

    const showError = () => {
        return <div className="alert alert-danger" style={{ display: error ? '' : "None " }}>
            {error}
        </div>
    }

    const showLoading = () => {
        return loading && (<div className="alert alert-info"><h2>Loading...</h2></div>)
    }

    const redirectUser = () => {
        if (redirectToReferrer) {
            if (isAuthenticated().store._id !== undefined) {
                return <Redirect to="/" />
            }
        }
    }

    return (
        <section className="h-100 gradient-form" style={{ backgroundColor: "#eee" }}>
            <div>
                {/* {showLoading()} */}
                {showError()}
                <div className="container py-5 h-100">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-xl-10">
                            <div className="card rounded-3 text-black">
                                <div className="row g-0">
                                    <div className="col-lg-6">
                                        <div className="card-body p-md-5 mx-md-4">

                                            <div className="text-center">
                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                                                    style={{ width: "185px" }} alt="logo" />
                                                <h4 className="mt-1 mb-5 pb-1">Welcome to ProductJi</h4>
                                            </div>

                                            <form action="" onSubmit={handleSubmit}>
                                                <div className="form-group">
                                                    <label className='text-muted'>Email</label>
                                                    <input onChange={handleChange('email')} type="email" className="form-control" value={email} />
                                                </div>

                                                <div className="form-group">
                                                    <label className='text-muted'>Password</label>
                                                    <input onChange={handleChange('password')} type="password" className="form-control" value={password} />
                                                </div>
                                                <button onClick={handleSubmit} type="submit" class="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3">Log In</button>
                                            </form>

                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                                        <div className="px-3 py-4 p-md-5 mx-md-4">
                                            <h4 className="mb-4">We are more than just a company</h4>
                                            <p className="small mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                                exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {redirectUser()}
            </div>
        </section >
    )
}

export default Signin
